import React, { useState } from 'react';
import { FaTrash, FaPencilAlt, FaSave, FaChevronDown, FaChevronUp } from 'react-icons/fa';

function CashFlowEntriesList({ entries, onDelete, onUpdate }) {
  const [isListVisible, setListVisible] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [editingEntryId, setEditingEntryId] = useState(null);
  const [editedEntry, setEditedEntry] = useState({});

  const handleEditClick = (entry) => {
    setEditingEntryId(entry.id);
    const formattedStartDate = new Date(entry.startDate).toISOString().split('T')[0];
    setEditedEntry({ ...entry, startDate: formattedStartDate });
  };

  const handleSaveClick = () => {
    onUpdate(editedEntry);
    setEditingEntryId(null);
  };

  const toggleGroup = (frequency) => {
    setExpandedGroups({
      ...expandedGroups,
      [frequency]: !expandedGroups[frequency],
    });
  };

  const groupedEntries = entries.reduce((groups, entry) => {
    let frequency = entry.frequency;

    // Group all SemiMonthly entries under a single "SemiMonthly" group
    if (!entry.isStartingBalance){
        if (frequency.includes('SemiMonthly')) {
        frequency = 'SemiMonthly';
        }
    }

    if (!groups[frequency]) {
      groups[frequency] = [];
    }
    groups[frequency].push(entry);
    return groups;
  }, {});

  const renderEntries = (entryGroup) => {
    return entryGroup.map((entry) => (
      <li
        key={entry.id}
        className={`flex justify-between items-center bg-gray-800 p-4 rounded-lg shadow-md ${
          entry.type === 'Starting_Balance' ? 'border-l-4 border-yellow-400' : ''
        }`}
      >
        <div className="flex-grow space-y-1">
          {editingEntryId === entry.id ? (
            <>
              <div>
                <label className="text-gray-400">Name:</label>
                <input
                  type="text"
                  value={editedEntry.name || (entry.type === 'Starting_Balance' ? 'Balance Set' : '')}
                  onChange={(e) => setEditedEntry({ ...editedEntry, name: e.target.value })}
                  className="p-2 bg-gray-700 text-white rounded-lg ml-2"
                  disabled={entry.type === 'Starting_Balance'} // Disable name editing for Starting_Balance
                />
              </div>
              <div>
                <label className="text-gray-400">Amount:</label>
                <input
                  type="number"
                  value={editedEntry.amount}
                  onChange={(e) => setEditedEntry({ ...editedEntry, amount: parseFloat(e.target.value) })}
                  className="p-2 bg-gray-700 text-white rounded-lg ml-2"
                />
              </div>
              {entry.type !== 'Starting_Balance' && (
                <div>
                  <label className="text-gray-400">Frequency:</label>
                  <select
                    value={editedEntry.frequency}
                    onChange={(e) => setEditedEntry({ ...editedEntry, frequency: e.target.value })}
                    className="p-2 bg-gray-700 text-white rounded-lg ml-2"
                  >
                    <option value="Once">Once</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="BiWeekly">BiWeekly</option>
                    <option value="SemiMonthly_1st_15th">1st and 15th of the Month</option>
                    <option value="SemiMonthly_15th_LastDay">15th and Last Day of the Month</option>
                    <option value="SemiMonthly_10th_25th">10th and 25th of the Month</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="SemiYearly">SemiYearly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
              )}
              <div>
                <label className="text-gray-400">Start Date:</label>
                <input
                  type="date"
                  value={editedEntry.startDate}
                  onChange={(e) => setEditedEntry({ ...editedEntry, startDate: e.target.value })}
                  className="p-2 bg-gray-700 text-white rounded-lg ml-2"
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center space-x-2">
                <span className="font-semibold text-gray-400">Name:</span>
                <span className="text-white">{entry.name || (entry.type === 'Starting_Balance' ? 'Balance Set' : '')}</span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="font-semibold text-gray-400">Amount:</span>
                <span className={`text-${entry.type === 'Income' || entry.type === 'Starting_Balance' ? 'green' : 'red'}-500`}>
                  ${parseFloat(entry.amount).toFixed(2)}
                </span>
              </div>
              {entry.type !== 'Starting_Balance' && (
                <div className="flex items-center space-x-2">
                  <span className="font-semibold text-gray-400">Frequency:</span>
                  <span className="text-white">{entry.frequency}</span>
                </div>
              )}
              <div className="flex items-center space-x-2">
                <span className="font-semibold text-gray-400">Start Date:</span>
                <span className="text-white">{new Date(entry.startDate).toLocaleDateString()}</span>
              </div>
            </>
          )}
        </div>
        <div className="flex items-center ml-4">
          {editingEntryId === entry.id ? (
            <button onClick={handleSaveClick} className="text-green-500 hover:text-green-700 mr-4">
              <FaSave />
            </button>
          ) : (
            <button onClick={() => handleEditClick(entry)} className="text-blue-500 hover:text-blue-700 mr-4">
              <FaPencilAlt />
            </button>
          )}
          <button onClick={() => onDelete(entry.id)} className="text-red-500 hover:text-red-700">
            <FaTrash />
          </button>
        </div>
      </li>
    ));
  };

  return (
    <div className="mt-8">
      <button
        type="button"
        onClick={() => setListVisible(!isListVisible)}
        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300 mb-4"
      >
        {isListVisible ? 'Hide Cash Flow Entries' : 'Show Cash Flow Entries'}
      </button>
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isListVisible ? 'max-h-screen' : 'max-h-0'}`}>
        <div className="overflow-y-auto max-h-96">
            {Object.keys(groupedEntries).map((frequency) => (
            <div key={frequency || 'Set Balances'}>
                <button
                type="button"
                onClick={() => toggleGroup(frequency)}
                className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg shadow-md hover:bg-gray-600 transition duration-300 mb-2 flex justify-between items-center"
                >
                <span>{frequency === 'null' ? 'Set Balances' : frequency}</span>
                {expandedGroups[frequency] ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                <ul className={`space-y-2 ${expandedGroups[frequency] ? 'block' : 'hidden'}`}>
                {renderEntries(groupedEntries[frequency])}
                </ul>
            </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default CashFlowEntriesList;
