import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AuthProvider, AuthContext } from './AuthContext';
import Layout from './Layout';
import SubscriptionList from './SubscriptionList';
import CashFlowTracker from './CashFlowTracker';
import Login from './Login';
import Register from './Register';
import './index.css';

function App() {
  return (
    <div className="dark">
      <AuthProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<ProtectedRoute><SubscriptionList /></ProtectedRoute>} />
              <Route path="/cashflow" element={<ProtectedRoute><CashFlowTracker /></ProtectedRoute>} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Routes>
          </Layout>
        </Router>
      </AuthProvider>
    </div>
  );
}

function ProtectedRoute({ children }) {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <p>You must be logged in to view this page.</p>;
  }

  return children;
}

export default App;
