import React, { useState } from 'react';

function CashFlowEntryForm({ onSubmit }) {
  const [name, setName] = useState('BudgetFlow Entry');
  const [type, setType] = useState('Income');
  const [amount, setAmount] = useState('');
  const [frequency, setFrequency] = useState('');
  const [startDate, setStartDate] = useState('');
  const [menuLevel, setMenuLevel] = useState('top');
  const [isMenuVisible, setMenuVisible] = useState(false);

  const renderFrequencyMenu = () => {
    switch (menuLevel) {
      case 'short':
        return (
          <div className="space-y-2">
            <button type="button" onClick={() => { setFrequency('Daily'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Daily</button>
            <button type="button" onClick={() => { setFrequency('Weekly'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Weekly</button>
            <button type="button" onClick={() => setMenuLevel('top')} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Back</button>
          </div>
        );
      case 'medium':
        return (
          <div className="space-y-2">
            <button type="button" onClick={() => { setFrequency('BiWeekly'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">BiWeekly</button>
            <button type="button" onClick={() => setMenuLevel('semiMonthly')} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">SemiMonthly</button>
            <button type="button" onClick={() => { setFrequency('Monthly'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Monthly</button>
            <button type="button" onClick={() => setMenuLevel('top')} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Back</button>
          </div>
        );
      case 'long':
        return (
          <div className="space-y-2">
            <button type="button" onClick={() => { setFrequency('Quarterly'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Quarterly</button>
            <button type="button" onClick={() => { setFrequency('SemiYearly'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">SemiYearly</button>
            <button type="button" onClick={() => { setFrequency('Yearly'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Yearly</button>
            <button type="button" onClick={() => setMenuLevel('top')} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Back</button>
          </div>
        );
      case 'semiMonthly':
        return (
          <div className="space-y-2">
            <button type="button" onClick={() => { setFrequency('SemiMonthly_1st_15th'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">1st and 15th of the Month</button>
            <button type="button" onClick={() => { setFrequency('SemiMonthly_15th_LastDay'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">15th and Last Day of the Month</button>
            <button type="button" onClick={() => { setFrequency('SemiMonthly_10th_25th'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">10th and 25th of the Month</button>
            <button type="button" onClick={() => setMenuLevel('medium')} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Back</button>
          </div>
        );
      default:
        return (
          <div className="space-y-2">
            <button type="button" onClick={() => { setFrequency('Once'); setMenuVisible(false); }} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">One Time</button>
            <button type="button" onClick={() => setMenuLevel('short')} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Short</button>
            <button type="button" onClick={() => setMenuLevel('medium')} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Medium</button>
            <button type="button" onClick={() => setMenuLevel('long')} className="w-full bg-gray-700 text-white py-2 px-4 rounded-lg">Long</button>
          </div>
        );
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const entry = {
      name,
      type,
      amount: parseFloat(amount),
      frequency,
      startDate,
    };
    onSubmit(entry);
  };

  return (
    <form className="space-y-4 mb-6" onSubmit={handleFormSubmit}>
      <div className="flex flex-col">
        <label htmlFor="name" className="text-sm font-medium">Description:</label>
        <input
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="p-3 mt-1 bg-gray-800 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="type" className="text-sm font-medium">Type:</label>
        <select
          id="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="p-3 mt-1 bg-gray-800 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="Income">Income</option>
          <option value="Expense">Expense</option>
        </select>
      </div>
      <div className="flex flex-col">
        <label htmlFor="amount" className="text-sm font-medium">Amount:</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="p-3 mt-1 bg-gray-800 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="frequency" className="text-sm font-medium">Frequency:</label>
        {/* Render the frequency navigation menu */}
        <div className="relative">
          <button
            type="button"
            onClick={() => setMenuVisible(!isMenuVisible)} // Toggle the menu
            className="w-full bg-gray-800 text-gray-100 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            {frequency ? `Frequency: ${frequency}` : 'Select Frequency'}
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${isMenuVisible ? 'max-h-screen' : 'max-h-0'}`}
          >
            <div className="mt-4">
              {renderFrequencyMenu()}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <label htmlFor="startDate" className="text-sm font-medium">Start Date:</label>
        <input
          type="date"
          id="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="p-3 mt-1 bg-gray-800 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <button type="submit" className="w-full bg-yellow-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300">
        Add Entry
      </button>
    </form>
  );
}

export default CashFlowEntryForm;
