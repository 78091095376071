import React, { useState, useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import { AuthContext } from './AuthContext';
import SetStartingBalanceForm from './SetStartingBalanceForm';
import CashFlowEntryForm from './CashFlowEntryForm';
import CashFlowEntriesList from './CashFlowEntriesList';

function CashFlowTracker() {
  const [cashFlowEntries, setCashFlowEntries] = useState([]);
  const [balances, setBalances] = useState([]);
  const [activeDate, setActiveDate] = useState(new Date());
  const { user } = useContext(AuthContext);

  // State to manage form visibility
  const [isStartingBalanceFormVisible, setStartingBalanceFormVisible] = useState(false);
  const [isCashFlowFormVisible, setCashFlowFormVisible] = useState(false);

  useEffect(() => {
    if (user) {
      fetchBalances();
      fetchCashFlowEntries();
    }
  }, [activeDate, user]);

  const fetchBalances = () => {
    const year = activeDate.getFullYear();
    const month = activeDate.getMonth() + 1;

    const startDate = `${year}-${String(month).padStart(2, '0')}-01`;
    const endDate = new Date(year, month, 0).toISOString().split('T')[0];

    fetch(`${process.env.REACT_APP_BACKEND_URL}/cashflow/balances?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const parsedData = data.map(balance => ({
          ...balance,
          balance: parseFloat(balance.balance),
        }));
        setBalances(parsedData);
      })
      .catch((err) => console.error('Error fetching daily balances:', err));
  };

  const fetchCashFlowEntries = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/cashflow`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCashFlowEntries(data);
      })
      .catch((err) => console.error('Error fetching cash flow entries:', err));
  };

  const addCashFlowEntry = (entry) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/cashflow`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(entry),
    })
      .then((res) => res.json())
      .then((data) => {
        setCashFlowEntries([...cashFlowEntries, data]);
        fetchBalances(); // Refresh balances after adding a new entry
      })
      .catch((err) => console.error('Error adding cash flow entry:', err));
  };

  const deleteCashFlowEntry = (id) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/cashflow/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(() => {
        setCashFlowEntries(cashFlowEntries.filter(entry => entry.id !== id));
        fetchBalances(); // Refresh balances after deleting an entry
      })
      .catch((err) => console.error('Error deleting cash flow entry:', err));
  };

  const updateCashFlowEntry = (entry) => {
    console.log(entry)
    fetch(`${process.env.REACT_APP_BACKEND_URL}/cashflow/${entry.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(entry),
    })
      .then(() => {
        setCashFlowEntries(cashFlowEntries.map(e => (e.id === entry.id ? entry : e)));
        fetchBalances(); // Refresh balances after updating an entry
      })
      .catch((err) => console.error('Error updating cash flow entry:', err));
  };

  const handleSetStartingBalance = (entry) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/cashflow`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        ...entry,
        type: 'Starting_Balance',  // Ensure the type is correct
        isStartingBalance: true,  // Flag to indicate this is a starting balance
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log('Starting balance set:', data);
        fetchBalances();  // Refresh balances after setting starting balance
      })
      .catch((err) => console.error('Error setting starting balance:', err));
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const balanceForDay = balances.find((balance) => balance.date === date.toISOString().split('T')[0]);
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;

      if (balanceForDay && typeof balanceForDay.balance === 'number') {
        return (
          <p className={`text-xs md:text-sm ${balanceForDay.balance < 0 ? 'text-red-500' : isWeekend ? 'text-blue-400' : 'text-gray-200'}`}>
            ${balanceForDay.balance.toFixed(2)}
          </p>
        );
      } else {
        return null;
      }
    }
    return null;
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveDate(activeStartDate);
  };

  return (
    <div className="p-6 bg-gray-900 text-gray-100 rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-6">Cash Flow Tracker</h1>

      <button
        type="button"
        onClick={() => setStartingBalanceFormVisible(!isStartingBalanceFormVisible)}
        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300 mb-4"
      >
        {isStartingBalanceFormVisible ? 'Hide' : 'Set Initial Balance'}
      </button>

      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isStartingBalanceFormVisible ? 'max-h-screen' : 'max-h-0'}`}>
        <SetStartingBalanceForm onSubmit={handleSetStartingBalance} />
      </div>

      <button
        type="button"
        onClick={() => setCashFlowFormVisible(!isCashFlowFormVisible)}
        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300 mb-4"
      >
        {isCashFlowFormVisible ? 'Hide' : 'Add Cash Flow Entry'}
      </button>

      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isCashFlowFormVisible ? 'max-h-screen' : 'max-h-0'}`}>
        <CashFlowEntryForm onSubmit={addCashFlowEntry} />
      </div>

    <div className="mt-8">
    <Calendar
        onChange={setActiveDate}
        value={activeDate}
        tileContent={tileContent}
        onActiveStartDateChange={handleActiveStartDateChange}
        className="bg-gray-800 text-gray-100 rounded-lg shadow-lg text-sm md:text-base"
        prevLabel={<span className="text-lg">&lt;</span>}
        nextLabel={<span className="text-lg">&gt;</span>}
        tileClassName="p-2 sm:p-4"
        navigationLabel={({ date, view, label }) => (
        <div className="flex justify-between items-center px-4">
            <span className="text-lg cursor-pointer">{label}</span>
        </div>
        )}
        showDoubleView={false}
        next2Label={null}
        prev2Label={null}
    />
    </div>


      <CashFlowEntriesList
        entries={cashFlowEntries}
        onDelete={deleteCashFlowEntry}
        onUpdate={updateCashFlowEntry}
      />
    </div>
  );
}

export default CashFlowTracker;
