import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { FaTrashAlt } from 'react-icons/fa';
import { FaPlus, FaMinus } from 'react-icons/fa';

function SubscriptionList() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [newSubscription, setNewSubscription] = useState({
    name: '',
    cost: '',
    renewalDate: '',
    frequency: 'Monthly', // Default frequency
  });
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/subscriptions`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setSubscriptions(data))
        .catch((err) => console.error('Error fetching subscriptions:', err));
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSubscription({ ...newSubscription, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/subscriptions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(newSubscription),
      })
        .then((res) => res.json())
        .then((data) => {
          setSubscriptions([...subscriptions, data]);
          setNewSubscription({
            name: '',
            cost: '',
            renewalDate: '',
            frequency: 'Monthly', // Reset to default
          }); // Clear form
        })
        .catch((err) => console.error('Error adding subscription:', err));
    }
  };

  const handleDelete = (id) => {
    if (user) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/subscriptions/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => res.json())
        .then(() => {
          setSubscriptions(subscriptions.filter(sub => sub.id !== id));
        })
        .catch((err) => console.error('Error deleting subscription:', err));
    }
  };

  const formatRenewalDate = (renewalDate) => {
    const date = new Date(renewalDate);
    const now = new Date();
    
    const options = {
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    };
    
    if (date.getUTCFullYear() !== now.getUTCFullYear()) {
      options.year = 'numeric';
    }
  
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  

  return (
    <div className="p-6 bg-gray-900 text-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Your Subscriptions</h2>
      <ul className="space-y-4 mb-6">
        {subscriptions.map((sub) => (
          <li key={sub.id} className="p-4 bg-gray-800 rounded-lg shadow-md flex justify-between items-center group">
            <div>
              <h4 className="text-lg font-semibold">{sub.name}</h4>
              <p className="text-sm text-gray-400">Renews {formatRenewalDate(sub.renewalDate)}</p>
              <p className="text-xl font-bold text-indigo-400">${sub.cost}</p>
              <p className="text-sm text-gray-400">Frequency: {sub.frequency}</p>
            </div>
            <FaTrashAlt
              className="text-red-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              onClick={() => handleDelete(sub.id)}
            />
          </li>
        ))}
      </ul>

      <button
        onClick={() => setIsFormVisible(!isFormVisible)}
        className="flex items-center justify-center w-full bg-indigo-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
      >
        {isFormVisible ? <FaMinus className="mr-2" /> : <FaPlus className="mr-2" />}
        {isFormVisible ? 'Hide' : 'Add a New Subscription'}
      </button>

      <div
        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${isFormVisible ? 'max-h-screen' : 'max-h-0'}`}
      >
        <form onSubmit={handleSubmit} className="space-y-4 mt-4">
          <div>
            <label className="block text-sm font-medium mb-1">Name:</label>
            <input
              type="text"
              name="name"
              value={newSubscription.name}
              onChange={handleInputChange}
              required
              className="w-full p-2 bg-gray-700 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Cost:</label>
            <input
              type="number"
              name="cost"
              value={newSubscription.cost}
              onChange={handleInputChange}
              required
              className="w-full p-2 bg-gray-700 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Renewal Date:</label>
            <input
              type="date"
              name="renewalDate"
              value={newSubscription.renewalDate}
              onChange={handleInputChange}
              required
              className="w-full p-2 bg-gray-700 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Frequency:</label>
            <select
              name="frequency"
              value={newSubscription.frequency}
              onChange={handleInputChange}
              required
              className="w-full p-2 bg-gray-700 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>
          <button type="submit" className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300">
            Add Subscription
          </button>
        </form>
      </div>
    </div>
  );
}

export default SubscriptionList;
