import React, { useState } from 'react';

function SetStartingBalanceForm({ onSubmit }) {
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit({ amount: parseFloat(amount), startDate: date });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex flex-col">
        <label htmlFor="amount" className="text-sm font-medium">Starting Balance:</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="p-3 mt-1 bg-gray-800 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="date" className="text-sm font-medium">Effective Date:</label>
        <input
          type="date"
          id="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="p-3 mt-1 bg-gray-800 text-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <button type="submit" className="w-full bg-yellow-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300">
        Set Account Balance
      </button>
    </form>
  );
}

export default SetStartingBalanceForm;
