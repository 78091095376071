import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

function Layout({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <nav className="bg-gray-800 p-4 shadow-lg">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-2xl font-bold">Simple Subscription Manager</div>
          <div className="relative lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-100 focus:outline-none"
            >
              {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
            {isMenuOpen && (
              <div
                ref={menuRef}
                className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-lg transition-all duration-300 ease-in-out"
              >
                <ul className="flex flex-col space-y-2 p-4">
                  <li>
                    <Link
                      to="/"
                      className="block text-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg transition duration-300"
                      onClick={toggleMenu}
                    >
                      Subscriptions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/cashflow"
                      className="block text-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg transition duration-300"
                      onClick={toggleMenu}
                    >
                      Cash Flow Tracker
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/login"
                      className="block text-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg transition duration-300"
                      onClick={toggleMenu}
                    >
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/register"
                      className="block text-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg transition duration-300"
                      onClick={toggleMenu}
                    >
                      Register
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <ul className="hidden lg:flex lg:space-x-4">
            <li>
              <Link
                to="/"
                className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg transition duration-300"
              >
                Subscriptions
              </Link>
            </li>
            <li>
              <Link
                to="/cashflow"
                className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg transition duration-300"
              >
                Cash Flow Tracker
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg transition duration-300"
              >
                Login
              </Link>
            </li>
            <li>
              <Link
                to="/register"
                className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg transition duration-300"
              >
                Register
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <main className="p-6">
        {children}
      </main>
    </div>
  );
}

export default Layout;
